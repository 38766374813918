import { Suspense } from 'react'
import { Navigate, RouterProvider, createBrowserRouter } from 'react-router-dom'
import { LoadingSpinner } from './shared-components'

const router = createBrowserRouter([
  {
    path: '/',
    lazy: () => import('./Wrapper'),
    children: [
      {
        index: true,
        element: <Navigate to={'/trade'} replace />,
      },
      {
        path: '/trade',
        // rely on the logic within TradesRoute
        element: <Navigate to={'/trade/unknown'} replace />,
      },
      {
        path: '/trade/:symbol',
        lazy: () => import('./TradesRoute'),
      },
      {
        path: '/portfolio',
        lazy: () => import('./pages/portfolio'),
      },
      {
        path: '/sub-accounts',
        lazy: () => import('./pages/sub-accounts'),
      },

      {
        path: '/public-pools',
        lazy: () => import('./pages/public-pools'),
      },
      {
        path: '/public-pools/:public_pool_index',
        lazy: () => import('./pages/public-pool'),
      },
      {
        path: '/leaderboard',
        lazy: () => import('./pages/leaderboard'),
      },
      {
        path: '*',
        lazy: () => import('./pages/not-found'),
      },
    ],
  },
])

const App = () => (
  <Suspense fallback={<LoadingSpinner />}>
    <RouterProvider router={router} />
  </Suspense>
)

export default App

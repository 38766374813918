import React, { forwardRef, type ButtonHTMLAttributes, type MouseEvent } from 'react'

import smallLoader from 'img/shared/small-loader.svg'
import cn from 'js/util/cn'

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  onClick?: (e: MouseEvent<HTMLButtonElement>) => void
  isLoading?: boolean
  color?: 'green' | 'blue' | 'grey' | 'red' | 'secondaryBlue'
  plain?: boolean
  isSelected?: boolean
  type?: 'button' | 'submit' | 'reset'
  children: React.ReactNode
  isLoadingText?: string
}

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      isLoading,
      children,
      color = 'blue',
      plain = false,
      isSelected,
      isLoadingText,
      disabled,
      className,
      ...rest
    },
    forwardedRef,
  ) => (
    <button
      ref={forwardedRef}
      disabled={disabled || isLoading}
      className={cn(
        'cursor-pointer !outline-none transition-all disabled:cursor-default',
        'flex h-10 min-h-10 items-center justify-center truncate rounded-lg border-0 p-4 max-mobile:px-4 max-mobile:py-0',
        'text-base font-light text-white disabled:text-white-opaque',
        {
          'hover:bg-primary-blue-pale disabled:bg-primary-blue-pale bg-primary-blue-main':
            color === 'blue',
          'hover:bg-secondary-blue-pale disabled:bg-secondary-blue-pale bg-secondary-blue-main':
            color === 'secondaryBlue',
          'hover:bg-green-dark disabled:bg-green-dark bg-green-main': color === 'green',
          'hover:bg-red-dark disabled:bg-red-dark bg-red-main': color === 'red',
          'hover:bg-grey-dark disabled:bg-grey-dark bg-grey-main': color === 'grey',
          'bg-transparent text-white-opaque text-sm hover:bg-transparent max-mobile:px-3': plain,
          'text-primary-blue-main': plain && isSelected,
        },
        className,
      )}
      {...rest}
    >
      {isLoading ? (
        <>
          <img src={smallLoader} alt="" />
          {isLoadingText}
        </>
      ) : (
        children
      )}
    </button>
  ),
)

Button.displayName = 'Button'

import loadingGif from 'img/shared/loading.gif'

const LoadingSpinner = () => (
  <div className="fixed inset-0 z-[3] bg-black/40">
    <div className="fixed left-1/2 top-1/2 h-11 -translate-x-1/2 -translate-y-1/2">
      <img src={loadingGif} alt={loadingGif} className="max-w-16" />
    </div>
  </div>
)

export default LoadingSpinner
